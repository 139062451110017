<template>
	<el-dialog title="修改密码" :visible.sync="dialogVisible" width="466px" :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<el-form :rules="rules" ref="form" :model="form" label-position="top" class="demo-ruleForm clear-fix" v-if="changeCheckType">
			<!-- <el-form-item label="用户账号：" class="forget" prop="name">
				<el-input size="small" v-model.trim="form.name"></el-input>
			</el-form-item> -->
			<el-form-item label="原密码：" prop="oldPassword">
				<el-input size="small" type="password" v-model.trim="form.oldPassword"></el-input>
			</el-form-item>
			<el-form-item label="新密码：" prop="newPassword">
				<el-input size="small" type="password" v-model.trim="form.newPassword"></el-input>
			</el-form-item>
			<el-form-item label="确认新密码：" prop="newPasswordConfirm">
				<el-input size="small" type="password" v-model.trim="form.newPasswordConfirm"></el-input>
			</el-form-item>
		</el-form>
		<el-form :rules="rulesList" ref="phoneForm" :model="phoneForm" label-position="top" class="demo-ruleForm clear-fix" v-else>
			<el-form-item label="用户账号：" class="forget" prop="account">
				<el-input size="small" v-model.trim="phoneForm.account"></el-input>
			</el-form-item>
			<el-form-item label="手机号：" prop="phone">
				<el-input size="small" v-model.trim="phoneForm.phone" :placeholder="'请输入手机号'" clearable></el-input>
			</el-form-item>
			<el-form-item class="validateCode clearfix" label="验证码：" prop="validCode">
				<el-input size="small" class="float-l" :placeholder="'请输入验证码'" v-model.trim="phoneForm.validCode" clearable> </el-input>
				<el-button class="float-l changeImg" @click="getValidate">获取验证码</el-button>
			</el-form-item>
			<el-form-item label="新密码：" prop="newPwd">
				<el-input size="small" type="password" v-model.trim="phoneForm.newPwd"></el-input>
			</el-form-item>
			<el-form-item label="确认新密码：" prop="newPwdConfirm">
				<el-input size="small" type="password" v-model.trim="phoneForm.newPwdConfirm"></el-input>
			</el-form-item>
		</el-form>
		<!-- <p class="changeCheck" @click="changeCheckType = !changeCheckType">{{ changeCheckType ? '忘记密码，手机验证码更改' : '使用旧密码更改' }}</p> -->
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="onSave('ruleForm')">保 存</el-button>
			<el-button @click="resetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { Session } from '@/utils/storage.js';
export default {
	data() {
		var checkPwd = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value === this.form.oldPassword) {
				callback(new Error('新密码不能和原密码一致!'));
			} else {
				callback();
			}
		};
		var checknewPasswordConfirm = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.form.newPassword) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			dialogVisible: false,
			activeName: 'first',
			form: {
				// name: '',
				oldPassword: '',
				newPassword: '',
				newPasswordConfirm: '',
				type: 'administrator',
			},
			rules: {
				name: [{ required: true, message: '此项为必填项', trigger: 'blur' }],
				oldPassword: [{ required: true, message: '此项为必填项', trigger: 'blur' }],
				newPassword: [
					{
						required: true,
						validator: checkPwd,
						trigger: 'blur',
					},
				],
				newPasswordConfirm: [{ required: true, validator: checknewPasswordConfirm, trigger: 'blur' }],
			},
			phoneForm: {
				account: '',
				phone: '',
				validCode: '',
				newPwd: '',
				newPwdConfirm: '',
			},
			rulesList: {
				account: [{ required: true, message: '此项为必填项', trigger: 'blur' }],
				phone: [{ required: true, message: '此项为必填项', trigger: 'blur' }],
				validCode: [{ required: true, message: '此项为必填项', trigger: 'blur' }],
				newPwd: [
					{
						required: true,
						validator: checkPwd,
						trigger: 'blur',
					},
				],
				newPwdConfirm: [{ required: true, validator: checknewPasswordConfirm, trigger: 'blur' }],
			},
			changeCheckType: true,
		};
	},
	mounted() {},
	methods: {
		getValidate() {},
		onSave() {
			let formValidate = this.activeName === 'first' ? this.$refs.form : this.$refs.phoneForm;
			formValidate.validate((valid) => {
				if (valid) {
					this.$http.put(this.api['Passwords#update'].href, this.form).then((res) => {
						if (res.data && res.data.success) {
							this.dialogVisible = false;
							this.$message.success(res.data.message);
							// 清除缓存/token等
							Session.clear();
							// 使用 reload 时，不需要调用 resetRoute() 重置路由
							window.location.reload();
						}
					});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		resetForm() {
			this.$refs.form.resetFields();
			this.dialogVisible = false;
			this.form = {
				// name: '',
				oldPassword: '',
				newPassword: '',
				newPasswordConfirm: '',
			};
		},
	},
};
</script>passwordUpdate

<style lang="scss" scoped>
/deep/ .el-dialog__header {
	padding: 28px;
	color: #333;
}
/deep/ .el-dialog .el-dialog__body {
	padding: 0 28px 28px !important;
}
/deep/ .el-input {
	width: 100%;
	height: 30px;
	line-height: 30px;
}
/deep/ .el-form-item {
	margin-bottom: 20px;
}
/deep/ .el-form-item__label {
	padding-right: 5px;
	line-height: 14px;
	height: 14px;
	margin-bottom: 8px;
}
/deep/ .validateCode {
	.el-form-item__content {
		.el-input {
			width: calc(100% - 130px);
			margin-right: 16px;
		}
		.changeImg {
			text-align: center;
			letter-spacing: 2px;
			// width: 100px;
			color: #1db9b1;
			cursor: pointer;
			// padding: 6px 16px !important;
			margin-right: 0;
		}
	}
}
.changeCheck {
	text-align: end;
	margin-top: 8px;
	color: #1db9b1;
	cursor: pointer;
}
/deep/ .el-dialog .el-dialog__footer {
	text-align: center;
	padding-top: 0;
	padding-bottom: 28px;
}
</style>
